import { Quote } from "@/shared/types/Quote.interface";
import { QuoteDataUtils } from "@/shared/utils/QuoteDataUtils";
import { PartialPolicyStepType } from "../schema/PtzCaQuote";

export class PtzCaDataUtils {
    static getPolicyStepInitialValues(quote?: Quote): PartialPolicyStepType {
        // Destructure existing values from quote
        const { email, ratingZipcode, phone, policies, extra, id, marketing } = quote || {};

        // Use existing values if available, otherwise fall back to defaults
        const initialValues: PartialPolicyStepType = {
            email: email || "",
            ratingZipcode: ratingZipcode || "",
            phone: phone || "",
            policies: !!id && policies?.length ? policies : [QuoteDataUtils.createNewPolicy()],
            marketing: {
                hasEmailConsent: marketing?.hasEmailConsent ?? false,
                hasSMSConsent: marketing?.hasSMSConsent ?? false
            },
            extra: extra || {}
        };

        return initialValues;
    }
}
