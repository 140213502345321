import React from "react";
import { SAMPLE_POLICY_URL } from "../utils/constants";

const Exclusions = () => {
    return (
        <div className="flex flex-col gap-1 rounded-md bg-background-brand-b-secondary p-4 sm:mx-auto md:items-center">
            <h4 className="text-sm font-bold text-content-brand-b">What isn’t covered under Spot plans?</h4>
            <ul className="flex list-disc flex-col pl-5 text-xs text-content-brand-b sm:flex-row sm:justify-center sm:space-x-10">
                <li>Pre-existing conditions</li>
                <li>Breeding or pregnancy care</li>
                <li>Cosmetic surgeries</li>
            </ul>
            <p className="text-xs text-content-brand-b">
                Waiting periods apply. For a full list of exclusions please view a{" "}
                <a href={SAMPLE_POLICY_URL} target="_blank" className="text-content-brand-b underline">
                    CA sample plan
                </a>
            </p>
        </div>
    );
};

export default Exclusions;
