import React from "react";
import { Heading, IconButton, Dialog, DialogContent, DialogClose, DialogTitle, DialogDescription } from "@/shared/components/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import Link from "next/link";
import { DialogProps } from "@/shared/types/Modals";
import { SAMPLE_POLICY_URL } from "../utils/constants";
import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import Strings from "@/shared/utils/Strings.constants";

const FullCoverageDetailsModal: React.FC<DialogProps> = props => {
    const { open, onClose, samplePolicyUrl = "" } = props;

    function handleOverlayClick() {
        if (onClose) {
            onClose(false);
        }
    }

    function handleContentClick(event: React.MouseEvent) {
        event.stopPropagation();
    }

    const content = [
        {
            heading: "What’s Covered?",
            content:
                "Each type of coverage includes exam fees, diagnostics, and treatment. We even cover the most advanced treatments, like chemotherapy and surgery, and some you may not expect, such as acupuncture, so you can follow your vet’s recommendations with less worry about cost."
        },
        {
            heading: "Accidents",
            content:
                "Accident coverage will take care of costs for injuries and emergencies related to accidents, like torn ligaments, bite wounds, cuts, broken bones, lodged foreign objects, and toxic ingestions."
        },
        {
            heading: "Illnesses",
            content:
                "With illness coverage, you’ll be reimbursed for the costs of major and minor illnesses, such as cancer, arthritis, allergies, hypothyroidism, digestive problems, and UTIs."
        },
        {
            heading: "Hereditary & Congenital Conditions",
            content:
                "This coverage is for the costs of inherited conditions and birth defects that don’t show symptoms until later in your pet’s life, like heart disease, IVDD, eye disorders, and hip dysplasia."
        },
        {
            heading: "Behavioral Issues",
            content:
                "Behavioral issues coverage can help with the costs of vet visits for concerning behaviors that may be related to anxiety or compulsive behavior, like excessive licking, fur pulling, and destruction of the home."
        },
        {
            heading: "Sample Policy",
            content: (
                <>
                    View our{" "}
                    <Link href={SAMPLE_POLICY_URL} target="_blank" className="underline">
                        sample policy
                    </Link>
                    .
                </>
            )
        }
    ];

    return (
        <div onClick={handleOverlayClick}>
            <Dialog open={open}>
                <DialogContent onEscapeKeyDown={handleOverlayClick} onClick={handleContentClick}>
                    <VisuallyHidden asChild>
                        <DialogTitle>{Strings.COVERAGE_DETAILS}</DialogTitle>
                    </VisuallyHidden>
                    <VisuallyHidden asChild>
                        <DialogDescription>{Strings.COVERAGE_DETAILS}</DialogDescription>
                    </VisuallyHidden>
                    <DialogClose asChild>
                        <IconButton onClick={() => onClose && onClose(false)}>
                            <FontAwesomeIcon icon={faXmark} className="size-5 text-content-primary" />
                        </IconButton>
                    </DialogClose>
                    <div className="flex w-full flex-col gap-2 text-content-primary">
                        {content.map((item, index) => (
                            <div key={index}>
                                <Heading level="h3" className="text-lg font-semibold">
                                    {item.heading}
                                </Heading>
                                <p className="text-content-secondary">{item.content}</p>
                            </div>
                        ))}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default FullCoverageDetailsModal;
