import { PublicConfig } from "@/shared/PublicConfig";

export const CA_MAX_AGE_CAT = 12;
export const CA_MAX_AGE_DOG = 10;

export const CA_FOOTER_LINKS = [
    { label: "Terms of Use", href: "https://spotpetinsurance.ca/terms-conditions" },
    { label: "Privacy Policy", href: "https://spotpetinsurance.ca/privacy" },
    { label: "Sample Policy", href: "https://spotpetinsurance.ca/sample-policy" },
    { label: "Accessibility", href: "https://spotpetinsurance.ca/ada-compliance-notice" },
    { label: "Underwriting", href: "https://spotpetinsurance.ca/underwriting" }
];

export const CA_POLICY_FACTS = [
    "You can use any licensed veterinarian in Canada or the US, including specialists and emergency vets!",
    "When submitting a claim, include your itemized vet bill and medical records for quicker reimbursement!"
];

export const SAMPLE_POLICY_URL = PublicConfig.PTZ_CA.SPOT_MARKETING_URL + PublicConfig.PTZ_CA.SAMPLE_POLICY_PATH;
