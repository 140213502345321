import Link from "next/link";
import { SAMPLE_POLICY_URL } from "../utils/constants";

export default function FooterContent() {
    return (
        <>
            <div>
                The information herein is summarized. All Pet Insurance plans have waiting periods, annual deductible, co-insurance, benefit limits and exclusions. Specific
                products, features, coverage limits, rates, and discounts may vary by province, eligibility, and are subject to change. Medical Conditions that are noted,
                symptomatic or diagnosed prior to enrollment, or during a waiting period are pre-existing to Coverage and not eligible for reimbursement. Coverage availability
                varies by province. Not available in Quebec.
            </div>
            <div>
                For all terms and conditions visit &nbsp;
                <Link href={SAMPLE_POLICY_URL} target="_blank">
                    www.spotpetinsurance.ca/sample-policy
                </Link>
                . Preventive Care reimbursements are based on a schedule. Accident & Illness insurance reimbursements are based on eligible treatments and invoice amounts.
            </div>
            <div>
                Insurance plans are underwritten by Northbridge General Insurance Corporation and administered by PTZ Insurance Services Ltd. Insurance plans are marketed and
                produced by Spot Pet Insurance Services, ULC.
            </div>
        </>
    );
}
