import { useEffect } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { z } from "zod";
import { PetInfoEditor } from "@/shared/components/PetInfoEditor";
import { FormStepProps, OtherProps } from "@/shared/types/Form";
import { useModal } from "@/shared/hooks/useModal";
import { useBreeds } from "@/shared/hooks/useBreeds";
import { Quote } from "@/shared/types/Quote.interface";
import { PolicyStepSchema } from "../schema/PtzCaQuote";
import { QuoteDataUtils } from "@/shared/utils/QuoteDataUtils";
import { Badge, Button } from "@/shared/components/ui";
import { useFormParentContext } from "@/shared/contexts/FormParent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { useAppLayerContext } from "@/shared/contexts/AppLayer";
import Strings from "@/shared/utils/Strings.constants";
import { CA_MAX_AGE_CAT, CA_MAX_AGE_DOG } from "../utils/constants";
import { TOP_BREEDS_CA } from "@/shared/utils/constants";
import { UIUtils } from "@/shared/utils/UIUtils";

type AllPolicyStepProps = z.infer<typeof PolicyStepSchema>;
type StepKeys = Extract<keyof Quote, keyof AllPolicyStepProps>;

type AllProps = FormStepProps<Quote, StepKeys, OtherProps | undefined>;

export function PolicyEditor(props: AllProps) {
    const { appState } = useAppLayerContext();
    const { underwriter, asyncErrors, isQuoteUpdating } = appState;
    const { breeds } = useBreeds(underwriter);
    const { control, setError, setFocus, watch } = useFormContext<AllPolicyStepProps>();
    const { watch: watchParent, getValues: getParentValues } = useFormParentContext<Quote>();
    const quoteId = watchParent(`id`);
    const { updateQuote } = appState;
    const policies = watch("policies");
    const parentPolicies = watchParent("policies");
    const firstParentPolicyId = parentPolicies?.[0]?.id;

    const modal = useModal();

    // Initialize useFieldArray
    const { fields, append, remove } = useFieldArray<AllPolicyStepProps>({
        control,
        name: "policies"
    });

    const handleAddPet = () => {
        const newPolicy = QuoteDataUtils.createNewPolicy();
        append(newPolicy);
    };

    const handleDeletePet = async (index: number) => {
        if (!(await modal.confirm())) {
            return;
        }

        const deletedPolicyId = policies[index]?.id;
        remove(index);

        if (!!quoteId) {
            const parentValues = getParentValues();
            const updatedParentPolicies = parentValues.policies?.filter(p => p.id !== deletedPolicyId) ?? parentValues.policies;
            const updatedParentValues = { ...parentValues, policies: updatedParentPolicies };
            updateQuote?.mutate(updatedParentValues);
        }
    };

    useEffect(() => {
        if (!!asyncErrors?.length) {
            const invalidMicrochip = asyncErrors.find(error => error.id === `invalid-microchip`);
            if (!!invalidMicrochip) {
                const match = invalidMicrochip.at?.match(/pets\[(\d+)\]/);
                if (match) {
                    const index = match[1];
                    const fieldName = `policies[${index}].extra.microchipID` as keyof AllPolicyStepProps;
                    // Hitting race condition where setError doesn't display error message
                    // todo: investigate why
                    setTimeout(() => {
                        setError(fieldName, {
                            type: "manual",
                            message: "Invalid microchip ID"
                        });
                        setFocus(fieldName);
                    }, 100);
                }
            }
        }
    }, [asyncErrors, setError, setFocus]);

    const allowDelete = (index: number): boolean => {
        if (!firstParentPolicyId && !!policies?.length && policies.length > 1) return true;
        return policies[index]?.id !== firstParentPolicyId;
    };

    return (
        <div className="flex flex-1 flex-col gap-4">
            {fields.map((field, index) => {
                return (
                    <div key={field.id}>
                        <PetInfoEditor
                            petBreeds={breeds}
                            topBreeds={TOP_BREEDS_CA}
                            policyIndex={index}
                            showMicrochip={true}
                            showBirthDate={true}
                            allowDelete={allowDelete(index)}
                            onDelete={() => handleDeletePet(index)}
                            ageField={{
                                maxAgeCat: CA_MAX_AGE_CAT,
                                maxAgeDog: CA_MAX_AGE_DOG,
                                maxAgeError: `For %{age}+ year-old %{species}s, please call ${UIUtils.formatPhone(Strings.PTZ_CA.PHONE_NUMBER)} for a specialized quote.`
                            }}
                            styles={{
                                wrapper: "md:grid-cols-2 lg:grid-cols-3",
                                fields: {
                                    name: "order-1 md:col-span-2 lg:col-span-1",
                                    species: "order-2 lg:col-span-1",
                                    gender: "order-3 lg:col-span-1",
                                    birthdate: "order-4 lg:col-span-1",
                                    breed: "order-5 lg:col-span-1",
                                    microchip: "order-6 md:col-span-2 lg:col-span-1"
                                }
                            }}
                            containBreedDropdown
                        />
                    </div>
                );
            })}

            <div className="flex flex-col items-center justify-center gap-2 sm:flex-row sm:justify-start">
                <Button
                    variant="ghost"
                    startDecorator={<FontAwesomeIcon icon={faCirclePlus} size="lg" />}
                    className="text-nowrap"
                    onClick={handleAddPet}
                    disabled={isQuoteUpdating}
                >
                    {Strings.ADD_ADDITIONAL_PET}
                </Button>

                <Badge variant="secondary" className="rounded-md sm:rounded-full">
                    {Strings.PTZ_CA.MULTIPET_DISCOUNT_LABEL}
                </Badge>
            </div>
            <hr className="mb-7 border-stroke-primary" />
            {modal.render}
        </div>
    );
}
